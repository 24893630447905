<template>
  <v-container>
    <v-flex xs12>
      <h2>{{ `Admission Applications` }}</h2>
      <span class="subheading" />
    </v-flex>
    <v-divider class="my-3" />
    <v-card>
      <v-card-title>
        Signed Application
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="submittedApplications"
        :search="search"
      >
        <template v-slot:items="props">
          <td>{{ props.item.applicationId }}</td>
          <td class="text-xs-right">
            {{ props.item.firstName }}
          </td>
          <td class="text-xs-right">
            {{ props.item.lastName }}
          </td>
          <td class="text-xs-right">
            {{ props.item.email }}
          </td>
          <td class="text-xs-right">
            {{ props.item.homephone }}
          </td>
          <td class="text-xs-right">
            <div class="text-center">
              <v-menu
                v-if="props.item.rep"
                :close-on-content-click="false"
                :nudge-width="200"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    flat
                    small
                    v-on="on"
                  >
                    {{ props.item.rep.firstName }}
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <h3>
                      {{ props.item.rep.firstName }} {{ props.item.rep.lastName }}
                    </h3>
                  </v-card-title>
                  <v-card-text>
                    <p v-if="props.item.rep.agent">
                      Company: {{ props.item.rep.agent.companyName }}
                    </p>
                    <p>
                      Phone: {{ props.item.rep.phone }}
                    </p>
                    <p>
                      Email: {{ props.item.rep.email }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </td>
          <td class="text-xs-right">
            {{ props.item.status }}
          </td>
          <td class="justify-center">
            <v-btn
              small
              color="red"
              flat
              @click="viewAsPDF(props.item)"
            >
              PDF
            </v-btn>
            <v-menu
              offset-y
              bottom
              right
            >
              <v-btn
                slot="activator"
                color="info"
                small
                flat
              >
                Documents
              </v-btn>
              <v-list>
                <v-list-tile @click="viewDoc(props.item, 'Passport')">
                  <v-list-tile-title>
                    Passport
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="viewDoc(props.item, 'Transcript')">
                  <v-list-tile-title>
                    Transcript
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="viewDoc(props.item, 'CreditSummary')">
                  <v-list-tile-title>
                    Credit Summary
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
            <v-btn
              small
              color="primary"
              flat
              @click="approve(props.item)"
            >
              Approve
            </v-btn>
          </td>
        </template>
        <v-alert
          v-slot:no-results
          :value="true"
          color="error"
          icon="warning"
        >
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-card>
    <br>
    <v-card>
      <v-card-title>
        Approved Application
        <v-spacer />
        <v-text-field
          v-model="approvedSearch"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="approvedApplications"
        :search="approvedSearch"
      >
        <template v-slot:items="props">
          <td>{{ props.item.applicationId }}</td>
          <td class="text-xs-right">
            {{ props.item.firstName }}
          </td>
          <td class="text-xs-right">
            {{ props.item.lastName }}
          </td>
          <td class="text-xs-right">
            {{ props.item.email }}
          </td>
          <td class="text-xs-right">
            {{ props.item.homephone }}
          </td>
          <td class="text-xs-right">
            <div class="text-center">
              <v-menu
                v-if="props.item.rep"
                :close-on-content-click="false"
                :nudge-width="200"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    flat
                    small
                    v-on="on"
                  >
                    {{ props.item.rep.firstName }}
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <h3>
                      {{ props.item.rep.firstName }} {{ props.item.rep.lastName }}
                    </h3>
                  </v-card-title>
                  <v-card-text>
                    <p v-if="props.item.rep.agent">
                      Company: {{ props.item.rep.agent.companyName }}
                    </p>
                    <p>
                      Phone: {{ props.item.rep.phone }}
                    </p>
                    <p>
                      Email: {{ props.item.rep.email }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </td>
          <td class="text-xs-right">
            {{ props.item.status }}
          </td>
          <td class="justify-center">
            <v-btn
              small
              color="red"
              flat
              @click="viewAsPDF(props.item)"
            >
              PDF
            </v-btn>
            <v-menu
              offset-y
              bottom
              right
            >
              <v-btn
                slot="activator"
                color="info"
                small
                flat
              >
                Documents
              </v-btn>
              <v-list>
                <v-list-tile @click="viewDoc(props.item, 'Passport')">
                  <v-list-tile-title>
                    Passport
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="viewDoc(props.item, 'Transcript')">
                  <v-list-tile-title>
                    Transcript
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="viewDoc(props.item, 'CreditSummary')">
                  <v-list-tile-title>
                    Credit Summary
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </td>
        </template>
        <v-alert
          v-slot:no-results
          :value="true"
          color="error"
          icon="warning"
        >
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      applications: [],
      representatives:[],
      agents:[],
      search: '',
      approvedSearch: '',
      dialog: false,
      menu1: false,
      valid: false,
      rules: [],
      headers: [
        {
          text: 'Application ID',
          align: 'left',
          value: 'applicationId',
          width: '10%',
        },
        { text: 'First Name', value: 'firstName', width: '10%'},
        { text: 'Last Name', value: 'lastName', width: '10%' },
        { text: 'Email', value: 'email', width: '15%' },
        { text: 'Phone', value: 'phone', width: '10%' },
        { text: 'Agent', value: 'rep', width: '10%' },
        { text: 'Status', value: 'status', width: '10%' },
        { text: 'Action', value: '', width: '30%' },
      ],
    }
  },
  computed: {
    submittedApplications() {
      return this.detailedApps.filter(item => item.status === 'submitted' || item.status === 'signed')
    },
    approvedApplications() {
      return this.detailedApps.filter(item => item.status === 'approved')
    },
    detailedApps() {
      return this.applications.map((item) => {
          item.rep = this.getRepById(item.repId) || null
          return item
        }
      ) || []
    },
    detailedReps() {
      return this.representatives.map((item) => {
          item.agent = this.getAgentById(item.agentId) || null
          return item
        }
      ) || []
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('applications/allPlain')
        .then((data) => { this.applications = data })
      this.$store.dispatch('representatives/all')
        .then((data) => { this.representatives = data })
      this.$store.dispatch('agents/all')
        .then((data) => { this.agents = data })
    },
    getRepById(id) {
      return this.detailedReps.find(item => item.id == id)
    },
    getAgentById(id) {
      return this.agents.find(item => item.id == id)
    },
    viewAsPDF({ id, applicationId }) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      this.$store.dispatch('applications/getPdf', id)
        .then((data) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const url = URL.createObjectURL(file)
          //window.open(fileURL)

          a.href = url
          a.download = applicationId
          a.click()
          window.URL.revokeObjectURL(url)
        })
    },
    viewDoc({ id }, type) {
      const actionString = `get${type}`
      this.$store.dispatch(`applications/${actionString}`, id)
        .then((data) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
    },
    approve(application) {
      const app = application
      this.$store.dispatch('applications/approve', app.id)
        .then((data) => {
          console.log(data)
          this.applications = this.applications.map((item) => {
            if (data.id === item.id) {
              return data
            }
            return item
          })
        })
    },
  },
}
</script>
